<template>
    <div class="artist_photo">

      <v-progress-circular v-if="changeAvatarLoader" :size="115" :width="3" style="z-index: 1;" color="#20C4F5" indeterminate></v-progress-circular>

      <v-img :src="`${avatar.small}?cache=${avatar.key}`"
             v-bind:srcset="avatar.srcset ? `${avatar.small}?cache=${avatar.key} 1x, ${avatar.srcset}?cache=${avatar.key} 2x` : null"
             loading="lazy"
             @load="avatarLoaded"
             :class="{'default-image': isImageDefault, 'show': avatar.loaded}"
             @click="$refs['modal-image'].show()"
             alt="My profile avatar">
      </v-img>

        <button type="button" class="change_ava" @click="changeAva">
            <span class="material-icons">photo_camera</span>
        </button>


        <input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="photoUpload">

        <v-dialog v-if="clipperDialog" v-model="clipperDialog" width="500">

            <div class="photo_clipper">
                <button class="close_modal" @click="clipperDialog = false"></button>
                <clipper-fixed
                        preview="avatarPreview"
                        ref="clipper"
                        :ratio="240/269"
                        class="basic lg clipper-fixed"
                        :src="clipperAvatar"
                        bg-color="transparent"
                        :round="false"
                        :grid="false"
                        shadow="rgba(0,0,0,0.8)"
                        :area="50"
                        :handle-zoom-event="handleZoom"
                        @load="imgAvaLoad"
                        :rotate="0">
                    <div slot="placeholder">No image</div>
                </clipper-fixed>

                <clipper-range v-model="scale" :min="0.5" :max="2"></clipper-range>
                <div class="actions_clipper_photo">
                    <button type="button" class="tetriatary_btn small close_clipper_photo" @click="closeClipperDialog" v-ripple>Close</button>

                    <!-- Upload New Photo -->
                    <!--                    <input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="photoUpload">-->
                    <button type="button" class="primary_btn small" @click="changeAva()" v-ripple>Upload New Photo</button>
                    <button type="button" class="clip_photo primary_btn small" @click="clipImage" v-ripple>Save</button>
                </div>
            </div>
        </v-dialog>

        <modalImage ref="modal-image" :image="`${avatar.original}?cache=${avatar.key}`"/>

    </div><!-- END artist photo-->

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {baseUrlToBlob} from "@/utils/baseUrlToBlob";

const ModalImage = () => import("@/components/public/modalImage");

export default {
    name: "privateProfileAvatar",
    components: {
        ModalImage
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,

            isImageDefault: false,
            avatar: {
                original: '',
                small: '',
                srcset: '',
                loaded: false,
                key: new Date().getTime(),
            },
            uploadedFile: '',
            clipperAvatar: '',
            changeAvatarLoader: false,

            clipperDialog: false,
            scale: 0.5,

        }
    },
    watch: {
        scale(val) {
            this.$refs.clipper.setWH$.next(+val);
        },
        PROFILE: {
            handler() {
					    this.getUserAvatar();
              this.avatar.loaded = false;
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(['PROFILE', 'PROFILE_LOADER', 'CHANGE_USER_AVA', 'FILE_URL', 'userID', 'PROFILE_LOGO'])
    },
    mounted() {
        this.getUserAvatar();
    },
    methods: {
        ...mapActions(['GET_PROFILE', 'ADD_FILE', 'CHANGE_USER_DATA']),

        getUserAvatar() {
	        this.changeAvatarLoader = true;

          this.avatar.small = require('@/assets/image/svg/default_avatar.svg');
          if (this.PROFILE_LOGO && this.PROFILE_LOGO['240x270'] ) {
            this.avatar.small = this.PROFILE_LOGO['240x270'];
            this.avatar.srcset = this.PROFILE_LOGO['240x270@2x']
          }

          this.avatar.original = (this.PROFILE_LOGO && this.PROFILE_LOGO['960x1080']) ? this.PROFILE_LOGO['960x1080'] :require('@/assets/image/svg/default_avatar.svg');

	        this.isImageDefault = (this.PROFILE && !this.PROFILE.photo);
	        this.changeAvatarLoader = false;
          this.avatar.key = new Date().getTime();
        },
        avatarLoaded() {
            this.avatar.loaded = true;
        },
        imgAvaLoad() {
            this.scale = 0.5;
            this.$refs.clipper.setWH$.next(0.5);
        },
        handleZoom(scale) {
            if (!isNaN(scale)) {
                const limitedScale = Math.max(Math.min(2, scale), 0.1);
                this.scale = limitedScale;
                return limitedScale;
            }
        },

        async photoUpload() {
            this.uploadedFile = this.$refs.uploadedFile.files[0];
            this.clipperDialog = true;
            this.scale = 0.5;

            // pre load image url
            const fileReader = new FileReader();
            await fileReader.addEventListener('load', () => {
                this.clipperAvatar = fileReader.result;
            });
            fileReader.readAsDataURL(this.uploadedFile);
        },

        changeAva() {
          let accessToken = localStorage.getItem('accessToken');
          if (accessToken){
            this.$refs.uploadedFile.click();
            if (this.uploadedFile) {
              this.clipperDialog = true;
            }
          } else {
            console.log('changeAva -redirect login');
            this.$router.push({name: 'login'});
          }

        },
        clipImage: function () {
            this.changeAvatarLoader = true;

            const canvas = this.$refs.clipper.clip();
            let dataURL = canvas.toDataURL("image/png", 1); // canvas->image (base64)
            this.uploadedFile = baseUrlToBlob(dataURL);

            this.clipperDialog = false;


            // pre load image url
            // const fileReader = new FileReader();
            // fileReader.addEventListener('load', () => {
            //     this.avatar.small = fileReader.result;
            // });
            // fileReader.readAsDataURL(this.uploadedFile);

            let formData = new FormData();
            formData.append('file', this.uploadedFile);
            formData.append('type', 'user');

            const userData = {
                id: this.userID,
            }
            this.ADD_FILE(formData)
                .then(() => {
                    userData.photo = this.FILE_URL;
                })
                .catch(err => console.log(`ADD_FILE (private profile), ${err}`))
                .finally(() => {
                    if (userData.photo) {
                        this.CHANGE_USER_DATA(userData)
                            .then(() => {
                                this.$store.commit('SET_CHANGE_USER_AVA', true);
                                // this.changeAvatarLoader = false;
                            })
                            .catch(err => {
                                console.log(`saveUserData, ${err}`);
                            })
                            // .finally(() => this.changeAvatarLoader = false)
                    }
                })
        },
        closeClipperDialog() {
            this.clipperDialog = false;
        }
    }
}
</script>

<style lang="scss">
.v-image {
    opacity: 0;
    transition: 0.3s;

    &.show {
        opacity: 1;
    }
}
</style>
