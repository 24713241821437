<template>
    <div class="private-profile-layout">
        <projectIsUnderConstructionWarning style="position: relative; top: 60px; width: 100%; z-index: 1;"></projectIsUnderConstructionWarning>
        <slot></slot>
    </div>
</template>

<script>
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";
export default {
    name: "privateProfileLayout",
    components: {
        projectIsUnderConstructionWarning
    }
}
</script>

<style lang="scss">
.private-profile-layout {
    //padding-top: 60px;
    .private_profile {
        .head_profile {
            padding-top: 90px;
        }
        //padding-top: 30px;
    }
}
</style>