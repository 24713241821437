<template>
    <privateProfileLayout>
        <div class="private_profile">
            <div class="content-page">

                <!-- Skeleton Loader -->
                <div class="head_profile" v-if="loader === true">
                    <div class="container">
                        <headSkeletonLoader :parent="'private-profile'"></headSkeletonLoader>
                    </div>
                </div>

                <div class="head_profile" :class="viewContent" v-if="loader === false">
                    <div class="container">
                        <privateProfileAvatar></privateProfileAvatar>
                        <div class="artist_info">

                            <!-- VIEW PROFILE -->
                            <div class="mobile_artist_name" v-if="mobile && viewContent !== 'edit-profile'">
                                <h2 class="artist_name">{{ USER.fullName }}</h2>
                                <!-- more button -->
                                <v-menu
                                        transition="slide-y-transition"
                                        :close-on-content-click="true"
                                        offset-y
                                        nudge-left="21"
                                        nudge-bottom="10"
                                        origin="top center"
                                        content-class="menu-pointer charcoal">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
                                            <span class="material-icons">more_vert</span>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <!-- popup share link -->
                                        <v-list-item>
                                            <v-dialog
                                                    transition="dialog-bottom-transition"
                                                    max-width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <button type="button" v-bind="attrs" v-on="on">Share</button>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <div class="content_popup_share">
                                                        <div class="head_popup">Share</div>
                                                        <div class="input_box">
                                                            <input type="text" id="input-share-link"
                                                                   v-model="shareLink">
                                                            <button type="button" @click="copyUserLink">Copy</button>
                                                        </div>
                                                        <button type="button" @click="dialog.value = false"
                                                                class="close">Close
                                                        </button>
                                                    </div>
                                                </template>
                                            </v-dialog>
                                        </v-list-item>
                                        <!-- Report -->
                                        <v-list-item>
                                            <button type="button" class="report">Report</button>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <!-- EDIT-PROFILE -->
                            <template v-if="viewContent === 'edit-profile' && tabs === 2">
                                <div class="input-style-4">
                                    <label for="full-name">Full Name</label>
                                    <input type="text" id="full-name" required v-model="changeData.fullName">
                                </div>
                                <div class="input-style-4">
                                    <label for="address">Location</label>

                                    <vue-google-autocomplete
                                            v-model="changeData.address"
                                            id="map"
                                            classname="form-control"
                                            placeholder="City, state"
                                            types="(cities)"
                                            v-on:keyup="keypressLocation"
                                            v-on:placechanged="getAddressData">
                                    </vue-google-autocomplete>
                                </div>

                                <div class="about">
<!--                                    <label for="">Services</label>-->
<!--                                    <div class="wrap_checkbox_roles">-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="dj" value="Dj" v-model="userRoles">-->
<!--                                            <label for="dj">Dj</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="producer" value="Producer" v-model="userRoles">-->
<!--                                            <label for="producer">Producer</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="promoter" value="Promoter" v-model="userRoles">-->
<!--                                            <label for="promoter">Promoter</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="vendor" value="Vendor" v-model="userRoles">-->
<!--                                            <label for="vendor">Vendor</label>-->
<!--                                        </div>-->
<!--                                        <div class="material_checkbox">-->
<!--                                            <input type="checkbox" id="deco-artist" value="Deco Artist" v-model="userRoles">-->
<!--                                            <label for="deco-artist">Deco Artist</label>-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                            </template>
                            <template v-else>
                                <h2 class="artist_name" v-if="!mobile">
                                    {{ USER.fullName }}
                                    <template v-if="PROFILE && PROFILE.subscriptionPlan && PROFILE.subscriptionPlan === 'premium'">
                                        <v-tooltip top close-delay="0" transition="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="subscription_plan" v-bind="attrs" v-on="on"
                                                     :class="{'premium': PROFILE.subscriptionPlan === 'premium', 'founder': PROFILE.isFounder === true}">
                                                </div>
                                            </template>
                                            <div v-if="PROFILE.subscriptionPlan === 'premium' && PROFILE.isFounder === false">Premium account</div>
                                            <div v-if="PROFILE.subscriptionPlan === 'premium' && PROFILE.isFounder === true">Founder's account</div>
                                        </v-tooltip>
                                    </template>


                                    <button type="button" class="tootle_description" @click="toggleDescription" :class="{'expand': showDescription}"></button>
                                </h2>

                                <div class="artist_location" v-if="USER.address">
                                    {{ USER.address }}
                                </div>
                                <div class="artist_projects" v-if="YOUR_ARTISTS && YOUR_ARTISTS.length">
                                    <div class="label_">Projects:</div>
                                    <div class="list_artists">
                                        <div class="artists" v-for="artist in YOUR_ARTISTS" :key="artist.id">
                                            <div @click="redirectToArtistPage(artist.id, artist.identifier)">
                                                {{ artist.name.trim() }}
                                            </div>
                                            <span v-if="YOUR_ARTISTS && YOUR_ARTISTS.length > 1">,</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="follows">
                                    <div class="followers">
                                        <span class="count">{{ USER.followersCount }}</span>
                                        <router-link :to="{name: 'manageFollowers'}">Followers</router-link>
                                    </div>
                                    <div class="following">
                                        <span class="count">{{ USER.followingCount }}</span>
                                        <router-link :to="{name: 'following'}">Following</router-link>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>


                <div class="wrap_profile_music_events">
                    <div class="head_profile_music_events">
                        <v-container>
                            <v-tabs
                                    background-color="transparent"
                                    color="#fff"
                                    v-model="tabs">
                                <v-tab v-for="tab in tabsSections" :key="tab.index">
                                    {{ tab.name }}
                                </v-tab>
                            </v-tabs>
                            <div class="actions">
                                <button v-if="viewContent !== 'edit-profile'" type="button" class="edit_profile" @click="editProfile" v-ripple>Edit profile
                                </button>
                                <!-- more button -->
                                <v-menu
                                        transition="slide-y-transition"
                                        :close-on-content-click="true"
                                        offset-y
                                        nudge-left="21"
                                        nudge-bottom="10"
                                        origin="top center"
                                        content-class="menu-pointer charcoal">

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="#7481A6" class="more" v-bind="attrs" v-on="on">
                                            <span class="material-icons">more_vert</span>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <!-- popup share link -->
                                        <v-list-item>
                                            <v-dialog
                                                    transition="dialog-bottom-transition"
                                                    max-width="600">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <button type="button" v-bind="attrs" v-on="on">Share</button>
                                                </template>
                                                <template v-slot:default="dialog">
                                                    <div class="content_popup_share">
                                                        <div class="head_popup">Share</div>
                                                        <div class="input_box">
                                                            <input type="text" id="input-share-link"
                                                                   v-model="shareLink">
                                                            <button type="button" @click="copyUserLink">Copy</button>
                                                        </div>
                                                        <button type="button" @click="dialog.value = false"
                                                                class="close">Close
                                                        </button>
                                                    </div>
                                                </template>
                                            </v-dialog>
                                        </v-list-item>
                                        <!-- Report -->
                                        <v-list-item>
                                            <button type="button" class="report">Report</button>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                            </div>
                        </v-container>
                    </div>
                    <div class="content_profile_music_events">
                        <v-tabs-items touchless v-model="tabs">
                            <v-tab-item> <!-- MUSIC SECTION -->
                                <div class="music_section">

                                    <!-- ************** RECENTLY PLAYED ALBUMS ************** -->
                                    <div class="recently_played" :class="{'empty': !recentlyPlayedLoader && !PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length}">
                                        <v-container>
                                            <div class="head_profile_content">
                                                <span class="_head_name">Recently played</span>
                                                <button type="button" class="show_more_link" @click="showComingSoonDialog = true">History</button>
                                            </div>


                                            <div class="albums_row">
                                                <template v-if="recentlyPlayedLoader">
                                                    <albumGridSkeletonLoader v-for="index in 6" :key="index"
                                                                             :parent="'default_grid_albums'"></albumGridSkeletonLoader>
                                                </template>
                                                <template v-if="!recentlyPlayedLoader && PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length">
                                                    <albumGrid v-for="album in PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.slice(0, 6)"
                                                               :key="album.id"
                                                               :album="album"
                                                               :playZone="PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS">
                                                    </albumGrid>
                                                </template>
                                            </div>
	                                        <nothingHereYet v-if="!recentlyPlayedLoader && !PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length"></nothingHereYet >
<!--                                            <div class="nothing_albums" v-if="!recentlyPlayedLoader && !PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS.length">-->
<!--                                                Nothing here yet<br> Go explore!-->
<!--                                            </div>-->
                                        </v-container>
                                    </div>

                                    <!-- ************** RECENTLY LIKED ALBUMS ************** -->
                                    <div class="recently_liked" :class="{'empty': !recentlyLikedLoader && !PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length}">
                                        <v-container>
                                            <div class="head_profile_content">
                                            <span class="_head_name">
                                                Recently liked
                                            </span>
                                                <router-link :to="{name: 'library'}" v-ripple class="show_more_link">Library</router-link>
                                            </div>
                                            <div class="albums_row">
                                                <template v-if="recentlyLikedLoader">
                                                    <albumGridSkeletonLoader v-for="index in 6" :key="index"
                                                                             :parent="'default_grid_albums'"></albumGridSkeletonLoader>
                                                </template>
                                                <template v-if="!recentlyLikedLoader && PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length">
                                                    <albumGrid v-for="album in PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.slice(0, 6)"
                                                               :key="album.id"
                                                               :album="album"
                                                               :small="true"
                                                               :playZone="PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS">
                                                    </albumGrid>
                                                </template>
                                            </div>
<!--                                            <div class="nothing_albums" v-if="!recentlyLikedLoader && !PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length">-->
<!--                                                Nothing here yet<br> Go explore!-->
<!--                                            </div>-->
	                                        <nothingHereYet v-if="!recentlyLikedLoader && !PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS.length"></nothingHereYet>
                                        </v-container>
                                    </div>

                                    <!-- ************** UPLOADER ALBUMS ************** -->
                                    <!--                                <div class="uploaded_music">-->
                                    <!--                                    <v-container>-->
                                    <!--                                        <div class="head_profile_content">-->
                                    <!--                                            <span class="_head_name">-->
                                    <!--                                                Uploaded-->
                                    <!--                                            </span>-->
                                    <!--                                            <router-link :to="{name: 'uploads'}" class="show_more_link">Manage Uploaded</router-link>-->
                                    <!--                                        </div>-->
                                    <!--                                        <div class="wrap_music">-->
                                    <!--                                            <div class="upload_music">-->
                                    <!--                                                <span class="material-icons">add_circle_outline</span>-->
                                    <!--                                                Upload Music-->
                                    <!--                                            </div>-->
                                    <!--                                            <template v-if="uploadedAlbumsLoader">-->
                                    <!--                                                <albumGridSkeletonLoader v-for="index in 5" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>-->
                                    <!--                                            </template>-->
                                    <!--                                            <template v-if="!uploadedAlbumsLoader && PRIVATE_PROFILE_UPLOADED_ALBUMS.length">-->
                                    <!--                                                &lt;!&ndash;                                                :small="true"&ndash;&gt;-->
                                    <!--                                                <albumGrid-->
                                    <!--                                                    v-for="album in PRIVATE_PROFILE_UPLOADED_ALBUMS.slice(0, 6)"-->
                                    <!--                                                    :key="album.id"-->
                                    <!--                                                    :album="album"-->
                                    <!--                                                    :hide-artist="true"-->
                                    <!--                                                    :playZone="PRIVATE_PROFILE_UPLOADED_ALBUMS">-->
                                    <!--                                                </albumGrid>-->
                                    <!--                                            </template>-->

                                    <!--                                        </div>-->
                                    <!--                                    </v-container>-->
                                    <!--                                </div>-->
                                </div>
                            </v-tab-item>
                            <v-tab-item> <!-- EVENTS SECTION -->
                                <div class="events_section">
                                    <div class="uploaded_events">
                                        <v-container>
                                            <div class="head_profile_content">
                                            <span class="_head_name">
                                                Recently viewed
                                            </span>
                                            </div>
                                            <nothingHereYet></nothingHereYet>

                                        </v-container>
                                    </div>
                                </div>
                            </v-tab-item>
                            <v-tab-item> <!-- ABOUT SECTION -->
                                <div class="about_section">
                                    <v-container>
                                        <div class="head_profile_content">
                                        <span class="_head_name">
                                          Bio
                                        </span>
                                        </div>
                                        <div class="wrap_about_section">
                                            <template v-if="viewContent === 'edit-profile'">
                                                <TextareaAutosize v-model="changeData.description"></TextareaAutosize>
                                                <div class="wrap_action_btn">
                                                    <button type="button" class="save" v-ripple @click="saveUserData">Save</button>
                                                    <button type="button" class="close" v-ripple @click="cancel">Cancel</button>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <template v-if="USER.description">
                                                    <div v-html="formattedBio"></div>
                                                </template>
                                                <template v-else>
<!--                                                    <div class="nothing_here_yet">-->
<!--                                                        <div class="container">-->
<!--                                                            Nothing here yet-->
<!--                                                        </div>-->
<!--                                                    </div>-->
	                                                <nothingHereYet></nothingHereYet>
                                                </template>
                                            </template>
                                        </div>
                                    </v-container>
                                </div>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>

                </div>
            </div>

            <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
        </div>
    </privateProfileLayout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueGoogleAutocomplete from 'vue-google-autocomplete';

import privateProfileLayout from "@/layouts/profiles/privateProfileLayout.vue";
import headSkeletonLoader from "@/components/small/skeleton-loaders/headSkeletonLoader";
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";
import privateProfileAvatar from "@/components/profile/privateProfileAvatar";
import albumGrid from '@/components/musicReleases/albumGrid';

const ComingSoonDialog = () => import("@/components/dialogs/comingSoonDialog");
import isMobile from "@/mixins/isMobile";
import checkAccessToken from "@/mixins/checkAccessToken";
import Defer from "@/utils/defer";
import nothingHereYet from "@/components/small/nothingHereYet";

export default {
    name: "profile-private",
    components: {
        privateProfileLayout,
        privateProfileAvatar,
        albumGrid,
        ComingSoonDialog,
        VueGoogleAutocomplete,
        headSkeletonLoader,
        albumGridSkeletonLoader,
        nothingHereYet
    },
    mixins: [checkAccessToken, isMobile, Defer()],
    data() {
        return {
			changeData: {
				fullName: '',
				address: '',
				description: '',
			},
            showComingSoonDialog: false,
            viewContent: 'my-profile',
            shareLink: '',

            tabsSections: [
                {index: 0, name: 'Music'},
                {index: 1, name: 'Events'},
                {index: 2, name: 'About'}
            ],
            tabs: null,

            showDescription: false, // mobile

            loader: false,
            changeDataLoader: false,
            recentlyPlayedLoader: false,
            recentlyLikedLoader: false,
            uploadedAlbumsLoader: false,
            location: '',
        }
    },

    watch: {
		// USER: {
		// 	handler() {
		// 		console.log('this.user', this.USER);
		// 	},
		// 	deep: true
		// },
		'USER.fullName': function (val) {
			if (!this.checkAccessToken()) {
				console.log('watch USER.fullName -redirect login');
				this.$router.push({name: 'login'});
			}
		},
	    'USER.address': function (val) {
		    if (!this.checkAccessToken()) {
			    console.log('watch USER.address -redirect login');
			    this.$router.push({name: 'login'});
		    }
	    },
		'USER.description': function (val) {
			if (!this.checkAccessToken()) {
				console.log('watch USER.description -redirect login');
				this.$router.push({name: 'login'});
			}
		}
    },

    computed: {
        ...mapGetters([
            'PROFILE',
            'USER', 'AUTH_DATA', 'userID',
            'YOUR_ARTISTS',
            'PRIVATE_PROFILE_UPLOADED_ALBUMS',
            'PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS',
            'PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS',
        ]),
        formattedBio() {
            return this.USER.description.replace(/\n/g, '<br>');
        }
    },
    mounted() {
        this.getData();
        this.getRecentlyPlayed();
        this.getRecentlyLiked();
    },
    methods: {
        ...mapActions([
            'GET_USER', 'CHANGE_USER_DATA', 'GET_YOUR_ARTISTS',
            'GET_RECENTLY_PLAYED',
            'GET_PRIVATE_PROFILE_UPLOADED_ALBUMS',
            'GET_PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS',
            'GET_PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS'
        ]),
	    redirectToArtistPage(artistId, artistName) {
		    // const name = artistName.trim().replace(/ /g, '-');
		    const routeData = this.$router.resolve({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		    window.open(routeData.href, '_blank');
	    },
		getUserData() {
			this.loader = true;
			this.GET_USER(this.userID)
				.then(() => {
					this.changeData.fullName = this.USER.fullName;
					this.changeData.address = this.USER.address;
					this.changeData.description = this.USER.description;
				})
				.catch(err => {
					console.log(`profile-private, GET_USER, ${err}`);
				})
				.finally(() => {
					this.loader = false;
				});
		},
        getData() {
            this.loader = true;
            this.uploadedAlbumsLoader = true;
            this.shareLink = `${window.location.hostname}/user/${this.userID}`;

	        this.getUserData();
            this.GET_YOUR_ARTISTS({owner: this.userID})
                .then(() => {
                    // if (this.YOUR_ARTISTS.length) {
                    //     let userArtistsID = [];
                    //     for (let i = 0; i < this.YOUR_ARTISTS.length; i++) {
                    //         userArtistsID.push(this.YOUR_ARTISTS[i].id);
                    //     }
                    //
                    //     let params = {
                    //         'artist': userArtistsID,
                    //         currentOwner: true,
                    //         'status': 'published'
                    //     }
                    //     this.GET_PRIVATE_PROFILE_UPLOADED_ALBUMS(params)
                    //         .catch(err => console.log(`GET_ARTIST_ALBUMS, ${err}`))
                    //         .finally(() => this.uploadedAlbumsLoader = false);
                    // } else {
                    //     this.uploadedAlbumsLoader = false;
                    // }
                });

                // this.GET_PRIVATE_PROFILE_UPLOADED_ALBUMS({currentOwner: true, status: 'published'})
                //     .catch(err => console.log(`GET_ARTIST_ALBUMS, ${err}`))
                //     .finally(() => this.uploadedAlbumsLoader = false);
        },

        getRecentlyPlayed() {
            this.recentlyPlayedLoader = true;
            let params = {status: 'published', order: 'desc', limit: 6}
            this.GET_PRIVATE_PROFILE_RECENTLY_PLAYED_ALBUMS(params)
                .catch(err => console.log(`getRecentlyPlayed, ${err}`))
                .finally(() => {
                    this.recentlyPlayedLoader = false;
                })
        },
        getRecentlyLiked() {
            this.recentlyLikedLoader = true;
            let params = {status: 'published', order: 'desc', limit: 6}
            this.GET_PRIVATE_PROFILE_RECENTLY_LIKED_ALBUMS(params) // TODO: need to sort by date added to the library
                .catch(err => console.log(`getRecentlyLiked, ${err}`))
                .finally(() => {
                    this.recentlyLikedLoader = false;
                })
        },

        saveUserData() {
	        this.loader = true;
	        this.viewContent = 'my-profile';
	        this.changeDataLoader = true;

            const userData = {
                id: this.userID,
                fullName: this.changeData.fullName,
                address: this.changeData.address,
                description: this.changeData.description,
            };

            this.CHANGE_USER_DATA(userData)
                .then(() => {
					this.getUserData();
                })
                .catch(err => {
                    console.log(`saveUserData, ${err}`);
                })
                .finally(() => this.changeDataLoader = false);
        },

        keypressLocation(event) {
			console.log('keypressLocation', event.target.value);
			if (event.target.value) {
				this.changeData.address = event.target.value;
			}
        },
        getAddressData: function (addressData, placeResultData) {
			console.log('getAddressData', addressData);
            if (addressData.country === 'United States') {
                this.changeData.address = `${addressData.locality},  ${addressData.administrative_area_level_1}, ${addressData.country}`;
            } else {
                this.changeData.address = `${addressData.locality}, ${addressData.country}`;
            }
        },
        editProfile() {
	        let accessToken = localStorage.getItem('accessToken');
			if (accessToken) {
				this.viewContent = 'edit-profile';
				this.tabs = 2; // aboutSection
			} else {
				console.log('editProfiles -redirect login');
				this.$router.push({name: 'login'});
			}
        },
        cancel() {
            this.viewContent = 'my-profile';
            this.tabs = 0; // musicSection
	        this.changeData.fullName = this.USER.fullName;
	        this.changeData.address = this.USER.address;
			this.changeData.description = this.USER.description;
        },
        copyUserLink() {
            navigator.clipboard.writeText(this.shareLink);
        },
        toggleDescription() {
            this.showDescription = !this.showDescription;
        },
    }
}
</script>

<style>

</style>
